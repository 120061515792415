import { tierOrder, rankOrder } from "./constants";
export function calculateDistance(summoner, target) {
  const actualTarget = calculateLPNeeded(target);
  return actualTarget - getCurrentLP(summoner, target);
}

export function calculateLPNeeded(target) {
  return target + tierOrder.length * rankOrder.length * 100;
}

export function getCurrentLP(summoner, target) {
  if (!tierOrder.includes(summoner.tier)) {
    return tierOrder.length * rankOrder.length * 100 + summoner.leaguePoints;
  }
  return (
    tierOrder.indexOf(summoner.tier) * rankOrder.length * 100 +
    rankOrder.indexOf(summoner.rank) * 100 +
    summoner.leaguePoints
  );
}

export function getPercentage(summoner, target) {
  return (getCurrentLP(summoner, target) / calculateLPNeeded(target)) * 100;
}
