import React from "react";
import "./App.css";
import emblemChallenger from "./emblem-challenger.png";
import kofi from "./kofi_s_logo_nolabel.png";
import discord from "./discord.png";
import github from "./github.png";
import farore from "./farore.webp";

import agent from "./agent";
import { ChakraProvider, position } from "@chakra-ui/react";
import { calculateDistance, getPercentage } from "./helper";
import {
  Text,
  Select,
  Input,
  InputGroup,
  IconButton,
  Progress,
  Button,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import {
  donation_url,
  github_url,
  personal_url,
  discord_url,
  leagueServers,
  tierColors,
} from "./constants";
import { useToast } from "@chakra-ui/react";

function Home() {
  const [lpTarget, setLPTarget] = React.useState("");

  const [currentServer, setCurrentServer] = React.useState(leagueServers[1]);
  const [summonerName, setSummonerName] = React.useState("Suicunê #EUNE");
  const [summoner, setSummoner] = React.useState(null);
  const [distance, setDistance] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      findSummoner();
    }
  };
  function scrollToElement() {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: "smooth", // Use 'auto' or 'smooth' for smooth scrolling
        block: "start", // Align the top of the element with the top of the visible area
        inline: "nearest", // Align the nearest edge of the element with the nearest edge of the visible area
      });
    }
  }
  const targetRef = React.useRef(null);
  const toast = useToast();
  const configureUI = async () => {
    try {
      setLPTarget(await agent.getLPTarget(currentServer.value));
    } catch (err) {
      console.log(err);

      toast({
        title: "There was en error ://",
        description: err?.response?.data?.error || err.toString(),
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const handleServerChange = (e) => {
    setCurrentServer(leagueServers.filter((server) => server.label === e)[0]);
  };

  async function findSummoner() {
    try {
      setIsLoading(true);
      const tempSummoner = await agent.getSummonerByName(
        currentServer.value,
        summonerName.split(" #")[0],
        summonerName.split(" #")[1]
      );
      setSummoner(tempSummoner);
      setIsLoading(false);
    } catch (err) {
      toast({
        title: "User not found.",
        description: err.toString(),
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    configureUI();
    // agent.searchSummonerByName("eun1", "Suicun");
  }, [currentServer]);

  React.useEffect(() => {
    if (summoner) {
      setDistance(calculateDistance(summoner, lpTarget));
      scrollToElement();
    }
  }, [summoner]);

  return (
    <ChakraProvider>
      <div className="App">
        <header className="App-header">
          <div>
            <InputGroup
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "12px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "148px",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Select
                  bg="#3182CE"
                  borderColor="#3182CE"
                  color="white"
                  value={currentServer.label}
                  onChange={(e) => handleServerChange(e.target.value)}
                >
                  {leagueServers.map((server) => (
                    <option
                      key={server.value}
                      value={server.label}
                      style={{ color: "black" }}
                    >
                      {server.label}
                    </option>
                  ))}
                </Select>
              </div>
              <Input
                bg="white"
                color="black"
                autoFocus
                placeholder="GameName #TagLine"
                value={summonerName}
                onKeyDown={handleKeyDown}
                onChange={(e) => setSummonerName(e.target.value)}
              />
              <IconButton
                colorScheme="blue"
                isLoading={isLoading}
                aria-label="Search database"
                icon={<SearchIcon />}
                onClick={findSummoner}
              />
            </InputGroup>
          </div>
          <img src={emblemChallenger} alt="logo" className="App-logo" />
          {lpTarget && (
            <Text fontSize="4xl">
              Threshold for Challenger in{" "}
              <span style={{ color: "#d09660" }}>{currentServer.label}</span>:
            </Text>
          )}

          {lpTarget && (
            <Text fontSize="5xl">
              <span>{lpTarget}</span>LP
            </Text>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "12px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text>Support me on Ko-fi:</Text>
            <img
              style={{ width: "64px", height: "64px", cursor: "pointer" }}
              src={kofi}
              onClick={() => window.open(donation_url)}
            ></img>
          </div>
        </header>
        <div
          style={{
            position: "absolute",
            right: "8px",
            top: "8px",
            display: "flex",
            flexDirection: "row",
            gap: "8px",
          }}
        >
          <img
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            src={discord}
            onClick={() => window.open(discord_url)}
          ></img>
          <img
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            src={github}
            onClick={() => window.open(github_url)}
          ></img>
          <img
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            src={farore}
            onClick={() => window.open(personal_url)}
          ></img>
        </div>
        {summoner && (
          <div className="App-header" ref={targetRef}>
            <div>
              <Text>{summoner.summonerName}</Text>

              <Text>
                {summoner.tier} {summoner.rank}
              </Text>
              <Text>{summoner.leaguePoints} LP</Text>
            </div>
            <div style={{ width: "70%" }}>
              <Progress
                value={getPercentage(summoner, lpTarget)}
                colorScheme={tierColors[summoner.tier]?.color}
                hasStripe
              />
              <Text>{getPercentage(summoner, lpTarget).toFixed(1)}%</Text>
            </div>
            <Text>Distance to Challenger: {distance} LP</Text>
            {summoner.leaguePoints > lpTarget && (
              <Text>Congratulations, you have reached Challenger! 🎉</Text>
            )}
          </div>
        )}
      </div>
    </ChakraProvider>
  );
}

export default Home;
