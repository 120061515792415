export const leagueServers = [
  {
    label: "BR",
    value: "br1",
  },
  {
    label: "EUNE",
    value: "eun1",
  },
  {
    label: "EUW",
    value: "euw1",
  },
  {
    label: "JP",
    value: "jp1",
  },
  {
    label: "KR",
    value: "kr",
  },
  {
    label: "LAN",
    value: "la1",
  },
  {
    label: "LAS",
    value: "la2",
  },
  {
    label: "NA",
    value: "na1",
  },
  {
    label: "OCE",
    value: "oc1",
  },
  {
    label: "TR",
    value: "tr1",
  },
  {
    label: "RU",
    value: "ru",
  },
  {
    label: "PH",
    value: "ph2",
  },
  {
    label: "SG",
    value: "sg2",
  },
  {
    label: "TH",
    value: "th2",
  },
  {
    label: "TW",
    value: "tw2",
  },
  {
    label: "VN",
    value: "vn2",
  },
];

export const tierOrder = [
  "IRON",
  "BRONZE",
  "SILVER",
  "GOLD",
  "PLATINUM",
  "EMERALD",
  "DIAMOND",
];

export const tierColors = {
  IRON: { color: "gray" },
  BRONZE: { color: "orange" },
  SILVER: { color: "cyan" },
  GOLD: { color: "yellow" },
  PLATINUM: { color: "teal" },
  EMERALD: { color: "green" },
  DIAMOND: { color: "blue" },
  MASTER: { color: "purple" },
  GRANDMASTER: { color: "red" },
  CHALLENGER: { color: "yellow" },
};

export const rankOrder = ["IV", "III", "II", "I"];

export const donation_url = "https://ko-fi.com/ulrichnyx";
export const discord_url = "https://discord.gg/unpxTBYSt4";
export const github_url = "https://github.com/UlrichNyx/untilChallenger";
export const personal_url = "https://fkont.com";

export const server_url = "https://api.amichallengeryet.com";
