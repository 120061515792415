import axios from "axios";
import { server_url } from "./constants";

async function getLPTarget(server) {
  try {
    const response = await axios.get(`${server_url}/lpTarget?server=${server}`);
    console.log(response);
    return response.data.leaguePoints;
  } catch (err) {
    throw err;
  }
}

async function getSummonerByName(server, gameName, tagLine) {
  try {
    const response = await axios.get(
      `${server_url}/search?gameName=${gameName}&tagLine=${tagLine}&server=${server}`
    );
    if (Object.keys(response.data).length === 0) {
      throw Error("This user does not have ranked data!");
    }
    return response.data;
  } catch (err) {
    throw err;
  }
}

export default {
  getLPTarget,
  getSummonerByName,
};
